import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Row } from '../components/ui'

const IndexPage = () => (
  <Layout>
    <SEO title="Coming Soon" />

    <Row flex={1} justifyContent="center" bg="#fff" color="#555" pt={5}>
      <h1>Coming Soon</h1>
    </Row>
  </Layout>
)

export default IndexPage
